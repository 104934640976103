import {getAction, deleteAction, putAction, postAction, httpAction} from '../api/manage'

export default {
    name: 'page-mixin',
    data() {
        return {
            defaultLoad: true,// 默认加载
            tableLoading: false,
            postType:'GET',
            query: {
                pageNo: 1,
                pageSize: 10,
            },
            searchParams: {}, // 搜索列表参数
            pageData: [], // 列表请求数据
            tableUrl: '' // 共用的列表请求地址
        }
    },
    beforeMount() {
        //    获取参数
    },
    beforeDestroy() {
    },
    created() {
        if (this.defaultLoad) {
            this.loadPageData()
        }
    },
    methods: {
        /**
         * @Description: 获取列表数据
         */
        loadPageData() {
            this.tableLoading = true
            const body = Object.assign({}, this.query, this.searchParams)
            getAction(this.tableUrl, body).then(res => {
                // 使用 filterData 进行数据格式化。
                if (res.data.code == 200) {
                    this.pageData = res.data.result || {}
                } else {
                    this.pageData = {
                        records: [],
                        total: 0,
                        size: 10,
                        current: 1,
                        pages: 1
                    }
                }
                this.tableLoading = false
            }, () => {
                this.tableLoading = false
            })
        },
        /**
         * @Description: 搜索事件
         */
        onSearch() {
            this.searchParams = Object.assign({}, this.condition)
            this.query.pageNo = 1
            this.loadPageData()
        },
        /**
         * @Description: 页码改变事件
         */
        sizeChange(value) {
            this.query.pageNo = 1
            this.query.pageSize = value
            this.loadPageData()
        },
        pageChange(value) {
            this.query.pageNo = value
            this.loadPageData()
        },
    }
}
