<template>
  <div>
    <div class="ly-wrap">
      <div class="currency-title" style="background-color: #ffffff;"><span class="back" @click="goWay('/')"></span>数据查询
      </div>
      <div class="ly-nav-bar">
        <div class="nav-item on" v-track-event="'实时数据, 切换'">实时数据</div>
        <div class="nav-item" v-track-event="'历史数据, 切换'" @click="goWay('/pump/history')">历史数据</div>
      </div>
    </div>

    <div class="ly-center">

      <div class="select-pump-box">
        <div class="st-p-item" @click="handleChangePump('')" :class="query.dataId === ''?'on':''">所有泵站</div>
        <div class="st-p-item" @click="handleChangePump('1440996711858720769')" :class="query.dataId === '1440996711858720769'?'on':''">阴河沟泵站</div>
        <div class="st-p-item" @click="handleChangePump('1678679215925895170')" :class="query.dataId === '1678679215925895170'?'on':''">河龙口泵站</div>
      </div>

      <div class="time">更新时间：{{ pageData.records[0].createTime || '--' }}</div>
      <div class="ly-table-wrap">
        <div class="tb-line tb-tt">
          <div class="sort">序号</div>
          <div class="name">变量</div>
          <div class="value">值</div>
        </div>
        <div class="tb-body">
          <div class="tb-line" v-for="(item,index) in pageData.records" :key="item.id">
            <div class="sort">
              <p>{{ index + 1 }}</p>
              <p>{{ item.pumpName }}</p>
            </div>
            <div class="name">
              <p class="zhName">{{ item.zhName }}</p>
              <p>{{ item.variableName }}</p></div>
            <div class="value" @click="handleGo(item)" v-track-event="'实时曲线, 进入'">
              <p class="vData">{{ item.variableData }}</p>
              <p>{{ item.variableUnit }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="ly-wrap">
      <ly-paging :pagination="pageData" @sizeChange="sizeChange" @pageChange="pageChange"></ly-paging>
    </div>
  </div>
</template>

<script>
import LyPaging from '../../components/LyPaging'
import moment from 'moment'
import {getSelectPump} from "../../utils/auth";
import storage from "../../store/storage";
import pageMixin from "../../mixin/pageMixin";

export default {
  name: 'PumpQuery',
  mixins: [pageMixin],
  components: {LyPaging},
  filters: {
    formatData(time) {
      return moment(time).format('YYYY-MM-DD HH:mm:ss')
    }
  },
  data() {
    return {
      query: {
        dataId: ''
      },
      defaultLoad: false,
      tableUrl: '/dac/variableData/queryPageByPumpId',
      pageData: {
        records: [
          {createTime: ''}
        ]
      },
      timer: null,
      interval: 5000
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    async loadData() {
      // const selectPump = await getSelectPump()
      // this.query.dataId = selectPump.id
      this.loadPageData()
      this.setTimer()
    },
    handleGo(item) {
      storage.put('condition', item)
      this.$router.push({path: '/pump/query/curve'})
    },
    handleChangePump(id){
      this.query.dataId = id
      this.loadPageData()
    },
    /** 定时器 */
    setTimer() {
      const _this = this
      this.timer = setInterval(() => {
        _this.loadPageData()
      }, _this.interval)
    },
    /** 关闭定时器 */
    closeTimer() {
      clearInterval(this.timer)
    },
    goWay(path) {
      this.$router.push({path: path})
    },
  },
  destroyed: function () {
    this.closeTimer()
  },
}
</script>

<style scoped lang="less">

.ly-nav-bar {
  width: 100%;
  height: 0.8rem;
  background-color: #DEEFFF;
  display: flex;

  .nav-item {
    flex: 1;
    height: 0.72rem;
    line-height: 0.72rem;
    text-align: center;
    font-weight: 400;
    color: #595959;
    font-size: 0.3rem;
    background-color: #DEEFFF;
  }

  .on {
    color: #FFFFFF;
    background-color: #0088FF;
  }
}


.select-pump-box {
  display: flex;

  .st-p-item {
    flex: 1;
    height: 0.44rem;
    background: url("~@/assets/img/choice.png") no-repeat left center;
    background-size: 0.44rem;
    display: inline-block;
    position: relative;
    top: 0.1rem;
    text-indent: 0.55rem;
    line-height: 0.5rem;
  }

  .on {
    background: url("~@/assets/img/choice-on.png") no-repeat left center;
    background-size: 0.44rem;
  }
}

.time {
  padding-top: 0.26rem;
  font-size: 0.32rem;
  color: #0088FF;
}

.ly-table-wrap {
  width: 100%;
  height: auto;
  border-radius: 10px;
  overflow: hidden;
  margin-top: 0.2rem;
  padding-bottom: 3rem;

  .tb-body {
    width: 100%;
    height: auto;
    //overflow-y: scroll;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .tb-line {
    height: 0.88rem;
    line-height: 0.88rem;
    background: #EDF6FF;
    border: 0px solid #000000;
    font-size: 0.28rem;
    font-weight: 400;
    color: #333333;
    display: flex;
    text-indent: 0.2rem;
    border-top: 1px solid #A6E1FFFF;

    .sort {
      padding-top: 0.05rem;
      width: 1.8rem;
      font-size: 0.26rem;
      line-height: 0.4rem;
    }

    .name {
      flex: 1;
      border-left: 1px solid #A6E1FFFF;
      border-right: 1px solid #A6E1FFFF;

      .zhName {
        padding-top: 0.1rem;
        font-size: 0.26rem;
        color: #0088FF;
      }

      p {
        line-height: 0.4rem;
        font-size: 0.24rem;
      }
    }

    .value {
      width: 2.2rem;
      color: #0088FFFF;
      background: url("~@/assets/img/more-dk.png") no-repeat 1.8rem center;
      background-size: 0.2rem;

      .vData {
        padding-top: 0.1rem;
        color: #0088FF;
        font-size: 0.26rem;
      }

      p {
        line-height: 0.4rem;
        font-size: 0.24rem;
        color: #333333;
      }
    }

  }

  .tb-line:first-child {
    border-top: none;
  }

  .tb-tt {
    background: #69B9FF;
    color: #FFFFFF;

    .name {
      border-left: 1px solid #CFEAFFFF;
      border-right: 1px solid #CFEAFFFF;
    }

    .value {
      color: #FFFFFF;
      background: none;
    }
  }
}
</style>

